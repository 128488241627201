/**
 * @description - Routes variable for different platforms. Contains list of RouteItem type objects that was passed to
 * renderRoutes method for Switch component.
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Lazy loading is the technique of rendering only-needed or
// critical user interface items first, then quietly unrolling the non-critical
// items later. It is now fully integrated into core react library itself.
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// Layouts
import { DashboardLayout } from 'src/layouts';
// Paths
import {
  requestsNavBarConfig,
  PATH_APP_AUTH,
  PATH_APP_MAIN,
  PATH_APP_REQUESTS,
} from './index';
// Types
import { TypeRouteItem } from 'src/@types';
// Components
import { Loadable, AuthGuard } from 'src/components';
import PlatformAccessGuard from 'src/components/guards/PlatformAccessGuard';

// ================================================================================================================== //
// ======================================================= PAGES ==================================================== //
// ================================================================================================================== //

// Application selection Pages
const CreateRequestPage = Loadable(lazy(() => import('src/pages/requests/create/index')));
const ManageRequestPage = Loadable(lazy(() => import('src/pages/requests/manage/index')));
// const ReportRequestPage = Loadable(lazy(() => import('src/pages/requests/reports')));
// const AnalyticsRequestPage = Loadable(lazy(() => import('src/pages/requests/analytics')));
const RequestsManagementDashboardPage = Loadable(lazy(() => import('src/pages/requests/dashboard/index')));
const PagePermissionDenied = Loadable(lazy(() => import('src/pages/access/PagePermissionDenied')));
const PageMaintenance = Loadable(lazy(() => import('src/pages/maintenance')));

// ================================================================================================================== //
// ===================================================== ROUTES ===================================================== //
// ================================================================================================================== //

/**
 * @description - Request routes list, contains list of RouteItem type objects.
 */
const requestsRoutes: TypeRouteItem = {
  path: PATH_APP_REQUESTS.root,
  element: (
    <AuthGuard path={PATH_APP_AUTH.rootFull}>
      <PlatformAccessGuard path={PATH_APP_MAIN.root}>
        <DashboardLayout navConfig={requestsNavBarConfig} />
      </PlatformAccessGuard>
    </AuthGuard>
  ),
  children: [
    { element: <Navigate to={PATH_APP_REQUESTS.dashboardFull} replace />, index: true },
    // Applications
    {
      path: PATH_APP_REQUESTS.dashboard,
      element: <RequestsManagementDashboardPage />
    },
    // Requests
    {
      path: PATH_APP_REQUESTS.requests.root,
      element: <Navigate to={PATH_APP_REQUESTS.requests.selfFull} replace />,
      index: true
    },
    {
      path: PATH_APP_REQUESTS.requests.create,
      element: <CreateRequestPage />
    },
    {
      path: PATH_APP_REQUESTS.requests.self,
      element: <ManageRequestPage isSelf={true} />
    },
    {
      path: PATH_APP_REQUESTS.requests.manage,
      element: <ManageRequestPage isSelf={false} />
    },
    // Reports
    {
      path: PATH_APP_REQUESTS.reports.root,
      element: <Navigate to={PATH_APP_REQUESTS.reports.reportFull} replace />,
      index: true
    },
    {
      path: PATH_APP_REQUESTS.reports.report,
      element: <PageMaintenance />
    },
    {
      path: PATH_APP_REQUESTS.reports.analytics,
      element: <PageMaintenance />
    },
  ]
};

export default requestsRoutes;
