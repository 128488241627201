/**
 * @description - The file contains all queries in order to fetch necessary data from firestore database
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Firestore
import { getFirestore, doc, query, where, orderBy, startAt, limit, collection } from 'firebase/firestore';
// Paths
import { employeesPaths, DEFAULT_NUMBER_OF_DATA } from 'src/configs';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

export function queryGetEmployeesListByCompanyUid(
  companyUid: string,
  orderByCategory?: string,
  categoryOrderSort?: 'asc' | 'desc',
  limitNumber?: number,
  pageNumber?: number,
) {
  // The employees reference in which the query should do fetching
  const queryDbReference = collection(getFirestore(), employeesPaths.COLLECTION_EMPLOYEES);
  // Returning the query
  return query(
    queryDbReference,
    // Checking company based documents
    where(employeesPaths.FIELD_COMPANY_UID, '==', companyUid),
    // Checking status based documents - processing/template/archive/removed
    where(employeesPaths.FIELD_ACTIVE, '==', true),
    // Ordering by created date
    orderBy(orderByCategory ? orderByCategory : employeesPaths.FIELD_DISPLAY_NAME, categoryOrderSort ?? 'asc'),
    // Pagination
    startAt(pageNumber ? pageNumber * (limitNumber ?? DEFAULT_NUMBER_OF_DATA) : 0),
    // If no limit provided the default is 150
    limit(limitNumber ?? DEFAULT_NUMBER_OF_DATA),
  );
}

export function getNewEmployeeReference() {
  // The document reference in which the query should do fetching
  return collection(getFirestore(), employeesPaths.COLLECTION_EMPLOYEES_REQUEST);
}

export function getEmployeeReference(uid: string) {
  // The document reference in which the query should do fetching
  return doc(getFirestore(), employeesPaths.COLLECTION_EMPLOYEES, uid);
}
