/**
 * @description - The file contains all necessary models for the document
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

import { Timestamp, DocumentData } from "firebase/firestore";
import { LanguageCode } from "src/@types/models/common";

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

// ======================================================= DOC ====================================================== //
/**
 * @description - The variables for document category
 */
export enum DocCategoryEnum {
  'contract' = 'contract',
  'document' = 'document',
  'template' = 'template',
}
export type DocCategory = keyof typeof DocCategoryEnum;

/**
 * @description - The document  statuses objects
 */
export enum DocumentStatusEnum {
  'active' = 'active',
  'processing' = 'processing',
  'draft' = 'draft',
  'terminated' = 'terminated',
  'archived' = 'archived',
  'removed' = 'removed',
  'all' = 'all',
  'internal_signature' = 'internal_signature',
  'customer_signature' = 'customer_signature',
  'done' = 'done'
}
export type DocumentStatus = keyof typeof DocumentStatusEnum;

/**
 * @description - The top level doc object model
 */
export type Doc = {
  // UIDs
  uid: string;
  company_uid: string;
  project_uid: string;
  template_uid: string | null;
  created_by_uid: string;
  updated_by_uid: string | null;
  // STATUS
  active: boolean;
  status: DocumentStatus;
  // CATEGORIES
  languages: LanguageCode[];
  category: DocCategory;
  // INFORMATION
  display_name: string;
  description: string;
  documents_number: number;
  // TIMESTAMP
  created: Timestamp;
  updated: Timestamp;
  reference_uid?: string | null;
  confirm_date?: string;
  signed_by?: (string | null)[];
  counterparty_uid?: string | null;
  customer_signature?: string | null;
  signed_date_by_customer?: any;
};

/**
 * @description - The method is converting the firestore db data into the doc object.
 * @param data - The firestore db data
 */
export function toDoc(data: DocumentData): Doc {
  return {
    uid: data.uid,
    company_uid: data.company_uid,
    project_uid: data.project_uid,
    template_uid: data.template_uid || null,
    created_by_uid: data.created_by_uid,
    updated_by_uid: data.updated_by_uid,
    active: data.active,
    status: data.status,
    languages: data.languages,
    category: data.category,
    display_name: data.display_name,
    description: data.description,
    documents_number: data.documents_number || 0,
    created: data.created,
    updated: data.updated,
    reference_uid: data.reference_uid ?? null,
    signed_by: data.signed_by ?? null,
    counterparty_uid: data.counterparty_uid || null,
    customer_signature: data.customer_signature || null,
    signed_date_by_customer: data.signed_date_by_customer || null,
  }
}

// ============================================ DOCUMENT OBJECT FROM DOC ============================================ //

/**
 * @description - The variables for the document types
 */
export enum DocumentTypesEnum {
  'contract' = 'contract',
  'supplement' = 'supplement',
  'attachment' = 'attachment',
  'act' = 'act',
  'termination' = 'termination',
}
export type DocumentType = keyof typeof DocumentTypesEnum;

/**
 * @description - The document model
 */
export type Document = {
  // UIDs
  uid: string;
  company_uid: string;
  doc_uid: string;
  created_by_uid: string;
  updated_by_uid: string | null;
  // STATUS
  active: boolean;
  status: DocumentStatus;
  order: number;
  // CATEGORIES
  type: DocumentType;
  // INFORMATION
  display_name: string;
  description: string;
  // TIMESTAMP
  created: Timestamp;
  updated: Timestamp;
};

/**
 * @description - The method is converting the firestore db data into the document object.
 * @param data - The firestore db data
 */
export function toDocument(data: DocumentData): Document {
  return {
    uid: data.uid,
    doc_uid: data.doc_uid || null,
    company_uid: data.company_uid,
    created_by_uid: data.created_by_uid,
    updated_by_uid: data.updated_by_uid,
    active: data.active,
    status: data.status,
    order: data.order,
    type: data.type,
    display_name: data.display_name,
    description: data.description,
    created: data.created,
    updated: data.updated,
  }
}

// ================================================ DOCUMENT CONTENT ================================================ //
/**
 * @description - The enumerated variable for content category
 */
export enum DocumentContentSectionEnum {
  'content' = 'content',
  'requisite' = 'requisite',
  'header' = 'header',
  'footer' = 'footer',
}
export type DocumentContentSection = keyof typeof DocumentContentSectionEnum;

/**
 * @description - The document content categories and styles models
 */
export type DocumentContentCategory = 'title' | 'subtitle1' | 'paragraph' | 'bullet' | 'link' | 'requisite' | 'signature';
export type DocumentContentFormat = 'center' | 'justify' | 'start';
export type DocumentContentStyle = 'bold' | 'normal' | 'underline' | 'italic';

/**
 * @description - The document content value type, that contains actual text and style of the section
 */
export type DocumentContentValue = {
  value: string | null;
  placeholder: string | null;
  editable: boolean;
  style: DocumentContentStyle;
}

/**
 * @description - The document content model
 */
export type DocumentContent = {
  // UIDs
  uid: string;
  created_by_uid: string,
  updated_by_uid: string,
  company_uid: string,
  document_uid: string,
  // INDEX
  order: number;
  // STATUS
  active: boolean;
  // CATEGORIES
  category: DocumentContentCategory;
  // TEXT STYLE
  format: DocumentContentFormat;
  style: DocumentContentStyle;
  // TIMESTAMP
  created: Timestamp;
  updated: Timestamp;
  // Point
  point: string | null;
  // VALUES
  values: Record<LanguageCode, DocumentContentValue[]>
}

export type DocumentRequisite = DocumentContent;
export type DocumentHeader = DocumentContent;
export type DocumentFooter = DocumentContent;

/**
 * @description - The method is converting the firestore db data into the DocumentContent model
 * @param data - The firestore database data
 */
export function toDocumentContent(data: DocumentData): DocumentContent {
  return {
    uid: data.uid,
    created_by_uid: data.created_by_uid,
    updated_by_uid: data.updated_by_uid,
    company_uid: data.company_uid,
    document_uid: data.document_uid,
    order: data.order,
    active: data.active,
    category: data.category,
    format: data.format,
    style: data.style,
    created: data.created,
    updated: data.updated,
    values: data.values,
    point: data.point ?? null
  }
}
