/**
 * @description - The logo only layout that contains full screen for the sub pages
 */
// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //
// Routing
import { Link as RouterLink, Outlet } from 'react-router-dom';
import { useState } from "react";
// @mui
import { styled } from '@mui/material/styles';
// components
import { LogoBisflowGreen } from '../../components';
// Paths
import {PATH_ROOTS_HOME} from '../../routes';
import {Box} from "@mui/material";
import DashboardHeader from "../dashboard/header";
import { useCollapseDrawer } from '../../hooks';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0)
  }
}));

/**
 * @description - The logo only layout wrapper for the pages
 * @constructor
 */
export default function LogoOnlyLayout() {
  return (
    <>
      <HeaderStyle>
        <RouterLink to={PATH_ROOTS_HOME}>
          <LogoBisflowGreen />
        </RouterLink>
      </HeaderStyle>
      <Outlet />
    </>
  );
}
