/**
 * @description - The firestore database paths
 */

// Common
const FIELD_UID = 'uid';
const FIELD_COMPANY_UID = 'company_uid';
const FIELD_USER_UID = 'user_uid';
const FIELD_ACTIVE = 'active';
const FIELD_CREATED = 'created';
const FIELD_UPDATED = 'updated';
const FIELD_STATUS = 'status';
const FIELD_ACCOUNT_TYPE = 'account_type';
const FIELD_DISPLAY_NAME = 'display_name';
const FIELD_DESCRIPTION = 'description';
const FIELD_PERMISSION = 'permission';
const FIELD_MANAGER_UID = 'manager_uid';
const FIELD_UNIT = 'unit';
const FIELD_ORDER = 'order';
const FIELD_CATEGORY = 'category';
const FIELD_TYPE = 'type';

// Employees Paths
const COLLECTION_EMPLOYEES = 'employees';
const COLLECTION_EMPLOYEES_REQUEST = 'employees_request';
const FIELD_PLATFORMS = 'platforms';

export const commonFields = {
  FIELD_COMPANY_UID,
  FIELD_ACTIVE,
}

// Employees paths
export const employeesPaths = {
  COLLECTION_EMPLOYEES,
  COLLECTION_EMPLOYEES_REQUEST,
  FIELD_COMPANY_UID,
  FIELD_ACTIVE,
  FIELD_USER_UID,
  FIELD_PLATFORMS,
  FIELD_DISPLAY_NAME,
}

// User based
const COLLECTION_USERS = 'users';

// User paths
export const userPaths = {
  COLLECTION_USERS,
  FIELD_UID,
};

// User based
const COLLECTION_PROFILES = 'profiles';

// User paths
export const profilesPaths = {
  COLLECTION_PROFILES,
  FIELD_UID,
  FIELD_COMPANY_UID,
  FIELD_ACCOUNT_TYPE,
  FIELD_DISPLAY_NAME,
  FIELD_USER_UID,
  FIELD_ACTIVE,
};

// Forms based
const COLLECTION_FORMS = 'forms';
const FIELD_FORM_TYPE = 'form_type';
const VALUE_FORM_TYPE__REQUEST = 'request';

export const formsPaths = {
  COLLECTION_FORMS,
  FIELD_COMPANY_UID,
  FIELD_ACTIVE,
  FIELD_FORM_TYPE,
  FIELD_CREATED,
  VALUE_FORM_TYPE__REQUEST,
}

// Projects based
const COLLECTION_PROJECTS = 'projects';
const FIELD_PROJECT_TYPE = 'project_type';
const FIELD_PROJECT_UID = 'project_uid';

export const projectsPaths = {
  COLLECTION_PROJECTS,
  FIELD_ACTIVE,
  FIELD_PROJECT_TYPE,
  FIELD_PERMISSION,
  FIELD_COMPANY_UID,
  FIELD_CREATED,
}

// Flows based paths
const COLLECTION_FLOWS = 'flows';
const SUB_COLLECTION_STEPS = 'steps';
const FIELD_ACTION = 'action';
export const flowsPaths = {
  COLLECTION_FLOWS,
  SUB_COLLECTION_STEPS,
  FIELD_COMPANY_UID,
  FIELD_PROJECT_UID,
  FIELD_CATEGORY,
  FIELD_CREATED,
  FIELD_ACTIVE,
  FIELD_DISPLAY_NAME,
  FIELD_STATUS,
  FIELD_ORDER,
  FIELD_ACTION,
}

const SUB_COLLECTION_DOCUMENTS = 'documents';
const SUB_COLLECTION_CONTENTS = 'contents';
const SUB_COLLECTION_REQUISITES = 'requisites';
const SUB_COLLECTION_HEADERS = 'headers';
const SUB_COLLECTION_FOOTERS = 'footers';
const SUB_COLLECTION_SIGNATURES = 'signatures';
const COLLECTION_DOCS = 'docs';
export const documentsPaths = {
  COLLECTION_DOCS,
  SUB_COLLECTION_CONTENTS,
  SUB_COLLECTION_REQUISITES,
  SUB_COLLECTION_HEADERS,
  SUB_COLLECTION_FOOTERS,
  SUB_COLLECTION_SIGNATURES,
  SUB_COLLECTION_DOCUMENTS,
  FIELD_COMPANY_UID,
  FIELD_PROJECT_UID,
  FIELD_CATEGORY,
  FIELD_CREATED,
  FIELD_ACTIVE,
  FIELD_DISPLAY_NAME,
  FIELD_ORDER,
  FIELD_STATUS,
  FIELD_TYPE,
}

const COLLECTION_ENTITIES = 'entities';
const FIELD_ENTITY_TYPE = 'entity_type';

export const departmentsPaths = {
  COLLECTION_ENTITIES,
  FIELD_ACTIVE,
  FIELD_CREATED,
  FIELD_UPDATED,
  FIELD_ENTITY_TYPE,
  FIELD_MANAGER_UID,
  FIELD_COMPANY_UID,
  FIELD_DISPLAY_NAME,
}

const COLLECTION_GROUPS = 'groups';

const SUB_COLLECTION_MEMBERS = 'members';
export const groupsPaths = {
  COLLECTION_GROUPS,
  SUB_COLLECTION_MEMBERS,
  FIELD_ACTIVE,
  FIELD_CREATED,
  FIELD_UPDATED,
  FIELD_STATUS,
  FIELD_MANAGER_UID,
  FIELD_COMPANY_UID,
  FIELD_DISPLAY_NAME,
}

const COLLECTION_BUDGETS = 'budgets';
const FIELD_BALANCE = 'balance';

export const budgetsPaths = {
  COLLECTION_BUDGETS,
  FIELD_ACTIVE,
  FIELD_CREATED,
  FIELD_UPDATED,
  FIELD_MANAGER_UID,
  FIELD_COMPANY_UID,
  FIELD_DISPLAY_NAME,
  FIELD_DESCRIPTION,
  FIELD_UNIT,
  FIELD_BALANCE,
}

export const collectionsPaths = {
  COLLECTION_EMPLOYEES,
  COLLECTION_USERS,
}

// Projects based
const COLLECTION_SUBSCRIPTIONS = 'subscriptions';

export const subscriptionsPaths = {
  COLLECTION_SUBSCRIPTIONS,
}
