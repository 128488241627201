// React
import { useLocation } from 'react-router-dom';
import { ReactNode } from 'react';
import { useAuth, useLocalStorage } from 'src/hooks';
import PagePermissionDenied from '../../pages/access/PagePermissionDenied';
import { LoadingScreen } from '../pages';
import { PATH_EDIT, PATH_CREATE } from 'src/routes';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The wrapper component's property interface.
 */
type PlatformAccessGuardProp = {
  children: ReactNode | string;
  path: string;
};

export default function PlatformAccessGuard({ children, path }: PlatformAccessGuardProp) {
  // Getting user based data
  const { employeeProfile, admin } = useAuth();
  // Checking for write access
  const { pathname } = useLocation();
  // Platform
  const platform = process.env.REACT_APP_NAME ?? 'BISFLOW';
  // getting the company profile;
  const [selectedCompany, setSelectedCompany] = useLocalStorage('company', {});
  if (!admin || !employeeProfile) {
    return (<LoadingScreen />);
  }

  // Checking if the admin view defined and user does not have proper administrative permission
  if (!(admin?.includes(selectedCompany?.user_uid) || employeeProfile?.platforms?.[platform.toLowerCase()]?.read === true)) {
    return (<PagePermissionDenied path={path} />);
  }
  // Checking if platform data editing page and the user does not have writable permission
  if (
    pathname?.split('/')?.some((subUrl) => [PATH_EDIT, PATH_CREATE].includes(subUrl))
    && employeeProfile?.platforms?.[platform.toLowerCase()]?.write !== true
  ) {
    return (<PagePermissionDenied path={path} />);
  }

  return <>{children}</>;
}
